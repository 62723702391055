import * as Sentry from "@sentry/react";
import {
  createFileRoute,
  redirect,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { useForm } from "react-hook-form";

import { getApplications, getInvitationDetails } from "@/api";
import LoaderScreen from "@/routes/-components/loader-screen";
import { getRouteByApplicationStage } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { Header } from "@prime/pop-components/src/layout";
import { maskPhone } from "@prime/ui/lib/masks";
import { Button } from "@prime/ui/src/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@prime/ui/src/form";
import { Input } from "@prime/ui/src/input";
import { AxiosError } from "axios";
import { z } from "zod";

export const Route = createFileRoute("/invitations/$invitationId/claim/")({
  beforeLoad: async (props) => {
    const { context, params, cause } = props;
    const { auth, apiClient } = context;
    const { isAuthenticated } = auth;
    const { invitationId } = params;

    if (isAuthenticated && cause === "enter") {
      try {
        const { applications = [] } = await getApplications({
          apiClient,
        });

        if (applications?.length) {
          const { to: redirectTo, params: redirectParams } =
            getRouteByApplicationStage(applications?.[0]);

          throw redirect({
            to: redirectTo,
            params: redirectParams,
            replace: true,
          });
        }
      } catch (error) {
        if (error instanceof AxiosError) {
          Sentry.captureException(error);
        } else {
          // This is a purposeful redirect "throw" and we don't want to catch it
          throw error;
        }
      }
    }

    try {
      const details = await getInvitationDetails({
        apiClient,
        params: {
          invitationId,
        },
      });

      return {
        details,
      };
    } catch (error) {
      const hostname = window.location.hostname;
      if (hostname === "localhost") {
        window.location.href = `http://localhost:3000/`;
        return;
      }
      window.location.href = `https://${hostname.replace("apply.", "www.")}/`;
    }

    return;
  },
  loader: async ({ context, location }) => {
    if (
      location.search &&
      "error" in location.search &&
      location.search.error === "offer_claim_failed"
    ) {
      context.toast({
        title: "There was an issue claiming your invitation.",
        description:
          "Please try again or contact support for further assistance.",
        variant: "destructive",
      });
    }
  },
  pendingComponent: LoaderScreen,
  component: Page,
});

const formSchema = z.object({
  firstName: z.string().min(1, "First name is required"),
  lastName: z.string().min(1, "Last name is required"),
  phoneNumber: z.string().min(10, "Phone number must be at least 10 digits"),
  emailAddress: z.string().email("Invalid email address"),
});

type FormValues = z.infer<typeof formSchema>;

function Page() {
  const params = useParams({ from: "/invitations/$invitationId/claim/" });

  const navigate = useNavigate({
    from: "/invitations/$invitationId/claim",
  });

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      emailAddress: "",
    },
  });

  const onSubmit = (data: FormValues) => {
    console.log("Form values:", data);
    localStorage.setItem("claimForm", JSON.stringify(data));
    navigate({
      to: "/invitations/$invitationId/disclosures",
      params: { invitationId: params.invitationId },
    });
  };

  return (
    <div className="flex min-h-screen w-screen flex-col">
      <Header />
      <div className="flex w-full flex-1 items-start justify-center">
        <div className="flex w-full max-w-[480px] flex-col gap-6 p-6">
          <div>
            <div className="text-icon w-full text-left text-sm font-normal uppercase leading-[150%]">
              Let&rsquo;s get started
            </div>
            <div className="text-txt-secondary w-full text-left text-xl font-light leading-[125%]">
              Create an account to secure your information and save your
              progress.
            </div>
          </div>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="flex flex-col gap-4"
            >
              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        placeholder="First Name"
                        autoComplete="given-name"
                        inputMode="text"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        placeholder="Last Name"
                        autoComplete="family-name"
                        inputMode="text"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="phoneNumber"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        placeholder="Phone Number"
                        valueModifier={maskPhone}
                        autoComplete="tel"
                        inputMode="tel"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="emailAddress"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        placeholder="Email Address"
                        autoComplete="email"
                        inputMode="email"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button
                type="submit"
                className="mt-6 h-12 w-full rounded-xl px-5 py-3 text-sm font-normal"
              >
                Continue
              </Button>
            </form>
          </Form>
        </div>
      </div>
    </div>
  );
}
