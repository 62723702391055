import * as Sentry from "@sentry/react";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { Loader2Icon } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import { claimOffer, getApplications, getInvitationDetails } from "@/api";
import { useAPI } from "@/contexts/api";
import LoaderScreen from "@/routes/-components/loader-screen";
import { getRouteByApplicationStage } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { Header } from "@prime/pop-components/src/layout";
import { formatPhoneForAuth } from "@prime/ui/lib/utils";
import { Button } from "@prime/ui/src/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@prime/ui/src/form";
import { InputOTP, InputOTPGroup, InputOTPSlot } from "@prime/ui/src/input-otp";
import { AxiosError } from "axios";
import { z } from "zod";

export const Route = createFileRoute(
  "/invitations/$invitationId/verification/"
)({
  beforeLoad: async (props) => {
    const { context, params, cause } = props;
    const { auth, apiClient } = context;
    const { isAuthenticated } = auth;
    const { invitationId } = params;

    if (isAuthenticated && cause === "enter") {
      try {
        const { applications = [] } = await getApplications({
          apiClient,
        });

        if (applications?.length) {
          const { to: redirectTo, params: redirectParams } =
            getRouteByApplicationStage(applications?.[0]);

          throw redirect({
            to: redirectTo,
            params: redirectParams,
            replace: true,
          });
        }
      } catch (error) {
        if (error instanceof AxiosError) {
          Sentry.captureException(error);
        } else {
          // This is a purposeful redirect "throw" and we don't want to catch it
          throw error;
        }
      }
    }

    try {
      const details = await getInvitationDetails({
        apiClient,
        params: {
          invitationId,
        },
      });

      return {
        details,
      };
    } catch (error) {
      const hostname = window.location.hostname;
      if (hostname === "localhost") {
        window.location.href = `http://localhost:3000/`;
        return;
      }
      window.location.href = `https://${hostname.replace("apply.", "www.")}/`;
    }

    return;
  },
  pendingComponent: LoaderScreen,
  component: Page,
});

type Verification = {
  method: "sms" | "email";
  value: string;
};

const FormSchema = z.object({
  code: z.string().min(6, {
    message: "Your one-time code must be 6 characters.",
  }),
});

function Verification({
  verification,
  onSubmit,
  isLoading,
  onHavingTrouble = () => {},
}: {
  verification: Verification;
  onSubmit: (data: z.infer<typeof FormSchema>) => void;
  isLoading?: boolean;
  onHavingTrouble?: () => void;
}) {
  const FormSchema = z.object({
    code: z.string().min(6, {
      message: "Your one-time code must be 6 characters.",
    }),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      code: "",
    },
  });

  const communicationType =
    verification.method === "sms" ? "a text message" : "an email";

  const communicationMechanism =
    verification.method === "sms" ? "phone number" : "email address";

  const buttonDisabled = !form.formState.isValid || isLoading;

  return (
    <>
      <div>
        <h1 className="text-2xl text-[#1D242E]">Enter Your Code</h1>
        <div className="w-full text-left text-[14px] leading-[150%] text-[#69737e]">
          <span>{`We sent ${communicationType} containing a verification code to the ${communicationMechanism} `}</span>
          <span className="font-normal text-[#4b4b55]">
            {verification.value}
          </span>
        </div>
      </div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex w-full flex-col gap-6"
        >
          <FormField
            control={form.control}
            name="code"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <InputOTP
                    maxLength={6}
                    onComplete={form.handleSubmit(onSubmit)}
                    autoFocus
                    {...field}
                  >
                    <InputOTPGroup>
                      <InputOTPSlot index={0} />
                      <InputOTPSlot index={1} />
                      <InputOTPSlot index={2} />
                      <InputOTPSlot index={3} />
                      <InputOTPSlot index={4} />
                      <InputOTPSlot index={5} />
                    </InputOTPGroup>
                  </InputOTP>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button
            variant="link"
            className="m-0 justify-start p-0 text-left text-sm font-light leading-[150%] text-[#595FEF] [text-decoration:underline]"
            onClick={onHavingTrouble}
          >
            Having Trouble?
          </Button>
          <Button
            type="submit"
            variant={buttonDisabled ? "outline" : "default"}
            className="mt-6 h-12 w-full rounded-xl px-5 py-3 text-sm font-normal"
            disabled={buttonDisabled}
          >
            Continue
            {isLoading ? (
              <Loader2Icon className="ml-2 h-4 w-4 animate-spin" />
            ) : null}
          </Button>
        </form>
      </Form>
    </>
  );
}

export function Page() {
  const { invitationId } = Route.useParams();
  const { toast } = Route.useRouteContext();
  const { auth, lendingService } = useAPI();
  const navigate = Route.useNavigate();
  const { exchangeCode } = auth;
  const [verificationType, setVerificationType] = useState<"sms" | "email">(
    "sms"
  );
  const [havingTrouble, setIsHavingTrouble] = useState(false);

  const [isClaiming, setIsClaiming] = useState(false);

  const { phoneNumber, emailAddress, firstName, lastName } = useMemo(
    () => JSON.parse(localStorage.getItem("claimForm") || "{}"),
    []
  );

  async function sendVerificationCodeToPhone() {
    try {
      setVerificationType("sms");
      setIsHavingTrouble(false);
      await auth.requestCode({ phoneNumber: formatPhoneForAuth(phoneNumber) });
    } catch (e) {
      console.error(e);
      toast({
        title: "Verification code failed to send",
        variant: "destructive",
      });
    }
  }

  async function sendVerificationCodeToEmail() {
    try {
      setVerificationType("email");
      setIsHavingTrouble(false);
      await auth.requestCode({ email: emailAddress });
    } catch (e) {
      console.error(e);
      toast({
        title: "Verification code failed to send",
        variant: "destructive",
      });
    }
  }

  useEffect(() => {
    if (!phoneNumber || !emailAddress) {
      navigate({
        to: "/invitations/$invitationId/claim",
        params: {
          invitationId,
        },
        search: {
          error: "missing_phone_or_email",
        },
      });
    }
  }, [phoneNumber, emailAddress, invitationId, navigate]);

  function onSubmit(data: z.infer<typeof FormSchema>) {
    setIsClaiming(true);
    exchangeCode(
      verificationType === "sms"
        ? {
            code: data.code,
            phoneNumber: formatPhoneForAuth(phoneNumber),
          }
        : {
            code: data.code,
            email: emailAddress,
          }
    )
      .then(() =>
        claimOffer({
          apiClient: lendingService,
          payload: {
            application_id: invitationId,
            first_name: firstName,
            last_name: lastName,
            user_email: emailAddress,
            signed_agreements: true,
          },
        })
      )
      .then((claimedOffer) =>
        navigate(getRouteByApplicationStage(claimedOffer?.data?.application))
      )
      .catch((e) => {
        Sentry.captureException(e);
        navigate({
          to: "/invitations/$invitationId/claim",
          params: {
            invitationId,
          },
          search: {
            error: "offer_claim_failed",
          },
        });
      })
      .finally(() => {
        setIsClaiming(false);
      });
  }

  return (
    <div className="flex min-h-screen w-screen flex-col">
      <Header />
      <div className="flex w-full flex-1 items-start justify-center">
        <div className="flex w-full max-w-[480px] flex-col gap-6 p-6">
          {!havingTrouble ? (
            <Verification
              verification={{
                method: verificationType,
                value: verificationType === "sms" ? phoneNumber : emailAddress,
              }}
              onSubmit={onSubmit}
              isLoading={isClaiming}
              onHavingTrouble={() => setIsHavingTrouble(true)}
            />
          ) : (
            <HavingTroubleScreen
              sendVerificationCodeToEmail={sendVerificationCodeToEmail}
              sendVerificationCodeToPhone={sendVerificationCodeToPhone}
              startOver={() => {
                localStorage.removeItem("claimForm");
                navigate({
                  to: "/invitations/$invitationId/claim",
                  params: {
                    invitationId,
                  },
                });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function HavingTroubleScreen({
  sendVerificationCodeToEmail,
  sendVerificationCodeToPhone,
  startOver,
}: {
  sendVerificationCodeToEmail: () => void;
  sendVerificationCodeToPhone: () => void;
  startOver: () => void;
}) {
  const { phoneNumber, emailAddress: email } = useMemo(
    () => JSON.parse(localStorage.getItem("claimForm") || "{}"),
    []
  );
  function Info({
    title,
    children,
  }: {
    title: string;
    children: React.ReactNode;
  }) {
    return (
      <div className="flex flex-col gap-2 rounded-lg border p-3">
        <p className="text-center text-[14px] font-normal">{title}</p>
        {children}
      </div>
    );
  }

  return (
    <>
      <div className="flex-shrink-0 flex-grow-0">
        <h1 className="text-2xl text-[#1D242E]">Let&rsquo;s figure this out</h1>
        <div className="w-full text-left text-[14px] leading-[150%] text-[#69737e]">
          If you haven&rsquo;t received a code to verify your account,
          there&rsquo;s a couple things we can do
        </div>
      </div>
      {phoneNumber && (
        <Info title={phoneNumber}>
          <Button
            onClick={sendVerificationCodeToPhone}
            size="sm"
            className="w-full"
          >
            Send a New Code via Text
          </Button>
        </Info>
      )}
      {email && (
        <Info title={email}>
          <Button
            onClick={sendVerificationCodeToEmail}
            size="sm"
            className="w-full"
          >
            Send a New Code via Email
          </Button>
        </Info>
      )}
      <Info title="My information is incorrect...">
        <Button size="sm" className="w-full" onClick={startOver}>
          Start Over
        </Button>
      </Info>
    </>
  );
}
