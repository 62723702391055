import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { Loader2Icon } from "lucide-react";
import { useState } from "react";

import { useAPI } from "@/contexts/api";
import CreditPull from "@/routes/_docs/docs/-content/credit-pull";
import ESign from "@/routes/_docs/docs/-content/e-sign";
import { PrivacyPolicy, TermsOfService } from "@prime/pop-components/src/docs";
import { Header } from "@prime/pop-components/src/layout";
import { formatPhoneForAuth } from "@prime/ui/lib/utils";
import { Button } from "@prime/ui/src/button";
import { ScrollArea } from "@prime/ui/src/scroll-area";

export const Route = createFileRoute("/invitations/$invitationId/disclosures/")(
  {
    component: Page,
  }
);

export function Page() {
  const params = useParams({ from: "/invitations/$invitationId/disclosures/" });
  const { auth } = useAPI();

  const [isRequestingCode, setIsRequestingCode] = useState(false);

  const navigate = useNavigate({
    from: "/invitations/$invitationId/disclosures",
  });

  const handleClick = async () => {
    const { phoneNumber } = JSON.parse(
      localStorage.getItem("claimForm") || "{}"
    );
    setIsRequestingCode(true);
    auth
      .requestCode({
        phoneNumber: formatPhoneForAuth(phoneNumber),
      })
      .then(() => {
        navigate({
          to: "/invitations/$invitationId/verification",
          params: { invitationId: params.invitationId },
        });
      })
      .catch((e) => {
        //TODO: throw a toasty
        console.error(e);
      })
      .finally(() => {
        setIsRequestingCode(false);
      });
  };

  return (
    <div className="flex min-h-screen w-screen flex-col">
      <Header />
      <div className="flex w-full flex-auto items-stretch justify-center">
        <div className="flex w-full max-w-[480px] flex-auto flex-col gap-6 p-6">
          <div className="flex-shrink-0 flex-grow-0">
            <h1 className="text-2xl text-[#1D242E]">Review Our Disclosures</h1>
            <div className="w-full text-left text-[14px] leading-[150%] text-[#69737e]">
              We believe in healthy business relationships
            </div>
          </div>
          <div className="flex-auto">
            <ScrollArea className="relative h-full min-h-64 w-full overflow-auto rounded-l-md border-2 border-[#DEE1E8] bg-white">
              <div className="absolute bottom-0 left-0 right-0 top-0 flex flex-col gap-3 p-2 sm:p-4">
                <br />
                <TermsOfService />
                <PrivacyPolicy />
                <ESign />
                <CreditPull />
                <br />
              </div>
            </ScrollArea>
          </div>
          <p className="flex-shrink-0 flex-grow-0 px-2 text-sm font-light">
            By clicking &rdquo;Continue&ldquo;, you agree to the Terms of
            Service, Privacy Policy, E-Sign Agreement, and Credit Pull Consent
            displayed above.
          </p>
          <Button
            onClick={handleClick}
            disabled={isRequestingCode}
            className="flex-shrink-0 flex-grow-0"
          >
            Continue
            {isRequestingCode ? (
              <Loader2Icon className="ml-2 h-4 w-4 animate-spin" />
            ) : null}
          </Button>
        </div>
      </div>
    </div>
  );
}
